import styled from "styled-components"

export const H1 = styled.h1`
  font-size: ${({ theme }) => `calc(1.2 * ${theme.typography!.baseFontSize})`};
  font-weight: 400;
  line-height: 1;
`

export const H2 = styled.h2`
  font-weight: bold;
`
export const H3 = styled.h3`
  font-weight: 400;
`

export const P = styled.p`
  font-weight: normal;
`
