import { Fragment, useContext, useState } from "react"
import styled from "styled-components"
import { ToneContext } from "../../contexts/ToneContext"

export interface KeyProps {
  isHighlighted?: boolean
  isTonic?: boolean
  isKeyInPrimaryOctave?: boolean
  isDominant?: boolean
  note?: { western: string; hindustani?: string; carnatic?: string }
  color: string
  isNextToBlackKey?: boolean
  key?: number
  octave?: number
  onMouseDown?: any
  onMouseUp?: any
  onMouseOut?: any
  onMouseEnter?: any
  isPressed?: boolean
}

export const WhiteKey = (props: KeyProps) => {
  return <StyledWhiteKey {...props} />
}

export const BlackKey = (props: KeyProps) => {
  return <StyledBlackKey {...props} />
}

export const Key = (props: KeyProps) => {
  const sampler = useContext(ToneContext)
  const [isPressed, setIsPressed] = useState<boolean>(false)
  const getNotes = (note: KeyProps["note"], octave: number) => {
    return [
      note!.western.replace("♭", "b") + (octave - 1),
      note!.western.replace("♭", "b") + octave,
    ]
  }
  const playNote = (note: KeyProps["note"], octave: number) => {
    setIsPressed(true)
    sampler.triggerAttack(getNotes(note, octave))
  }
  const stopNote = (note: KeyProps["note"], octave: number) => {
    setIsPressed(false)
    sampler.triggerRelease(getNotes(note, octave), "+0.32")
  }
  const events = {
    onMouseDown: (event: any) => playNote(props.note!, props.octave!),
    onMouseUp: (event: any) => stopNote(props.note!, props.octave!),
    onMouseOut: (event: any) => stopNote(props.note!, props.octave!),
    onMouseEnter: (event: any) =>
      event.buttons === 1 && playNote(props.note!, props.octave!),
  }
  return (
    <>
      {props.color === "black" ? (
        <BlackKey {...props} {...events} isPressed={isPressed} />
      ) : (
        <WhiteKey {...props} {...events} isPressed={isPressed} />
      )}
    </>
  )
}

const StyledKey = styled.div<KeyProps>`
  position: relative;
  user-select: none;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
  &:after {
    content: "";
    box-sizing: border-box;
    display: ${({ isHighlighted }) => (isHighlighted ? "block" : "none")};
    position: absolute;
    width: calc(100% - 4px);
    aspect-ratio: 1 / 1;
    bottom: 2px;
    left: 2px;
    border-radius: 2px;
    border: 3px solid transparent;
    background: ${({ theme }) => theme.palette!.primary[5]};
    opacity: ${({ isKeyInPrimaryOctave: isPrimaryOctave }) =>
      isPrimaryOctave ? "1" : "0.32"};
  }
`

export const StyledWhiteKey = styled(StyledKey)<KeyProps>`
  flex: 1;
  margin: ${({ isNextToBlackKey }) =>
    isNextToBlackKey ? "0 0 0 calc(-2.5% - 0.001%)" : "0"};
  z-index: 1;
  aspect-ratio: 1 / 4.8;
  background: ${({ isPressed }) => (isPressed ? "#d8d8d8" : "#f8f8f8")};
  border-left: 1px solid #ddd;
  &:first-child {
    border-left: none;
  }
  &:after {
    border-color: ${({ isTonic, isDominant }) =>
      isTonic
        ? "rgba(0, 0, 0, 0.48)"
        : isDominant
        ? "transparent"
        : "transparent"};
  }
`

export const StyledBlackKey = styled(StyledKey)`
  width: 5%;
  aspect-ratio: 1 / 4;
  height: 1%;
  margin: 0 0 0 calc(-2.5% - 0.001%);
  box-sizing: border-box;
  z-index: 2;
  border-radius: 0 0 40px 40px;
  background: ${({ isPressed }) => (isPressed ? "#000" : "#222")};
  &:after {
    border-radius: 400px;
    border-color: ${({ isTonic, isDominant }) =>
      isTonic
        ? "rgba(255, 255, 255, 0.88)"
        : isDominant
        ? "transparent"
        : "transparent"};
  }
`
