import styled from "styled-components"

export const Divider = styled.div`
  height: ${({ theme }) => theme.spacing!.sizes.xxxs};
  background: ${({ theme }) => theme.palette!.gray[9]};
`

export const Spacer = styled.div`
  height: ${({ theme }) => theme.spacing!.sizes.xxs};
  width: ${({ theme }) => theme.spacing!.sizes.xxs};
`
