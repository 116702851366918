import styled from "styled-components"

export const Container = styled.section`
  width: 375px;
  background: ${({ theme }) => theme.palette!.gray[10]};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
  }
`

export const Section = styled.section`
  padding: ${({ theme }) => theme.spacing!.sizes.s};
  flex-direction: column;
  display: flex;
  row-gap: ${({ theme }) => theme.spacing!.sizes.xxs};
`

export const Sticky = styled.div`
  position: sticky;
  width: 100%;
  top: 0;
  background: inherit;
`
