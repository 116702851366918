export interface Config {
  ragaSystem: MusicalSystem
  notationSystem: MusicalSystem
  tonic: string
  selectedRaga?: Raga
}

export enum MusicalSystem {
  hindustani,
  carnatic,
  western,
}

export interface Raga {
  name: string
  notes: number[]
}

export const numericToHindustaniDictionary = new Map<number, string>([
  [1, "S"],
  [1.5, "r"],
  [2, "R"],
  [2.5, "g"],
  [3, "G"],
  [4, "m"],
  [4.5, "M"],
  [5, "P"],
  [5.5, "d"],
  [6, "D"],
  [6.5, "n"],
  [7, "N"],
])

export const numericToCarnaticDictionary = new Map<number, string>([
  [1, "S"],
  [1.5, "R1"],
  [2, "R2"],
  [2.5, "G1"],
  [3, "G2"],
  [4, "M1"],
  [4.5, "M2"],
  [5, "P"],
  [5.5, "D1"],
  [6, "D2"],
  [6.5, "N1"],
  [7, "N2"],
])

export const numericToWesternDictionary = new Map<number, string>([
  [1, "C"],
  [1.5, "D♭"],
  [2, "D"],
  [2.5, "E♭"],
  [3, "E"],
  [4, "F"],
  [4.5, "G♭"],
  [5, "G"],
  [5.5, "A♭"],
  [6, "A"],
  [6.5, "B♭"],
  [7, "B"],
  [9, "C"],
  [9.5, "D♭"],
  [10, "D"],
  [10.5, "E♭"],
  [11, "E"],
  [12, "F"],
  [12.5, "G♭"],
  [13, "G"],
  [13.5, "A♭"],
  [14, "A"],
  [14.5, "B♭"],
  [15, "B"],
])

export const toAltNotation = (
  notes: number[],
  tonic: string,
  system: MusicalSystem
) => {
  let altNotes: string[] = []
  switch (system) {
    case MusicalSystem.hindustani:
      altNotes = notes.map((note) => numericToHindustaniDictionary.get(note)!)
      return altNotes
    case MusicalSystem.carnatic:
      altNotes = notes.map((note) => numericToCarnaticDictionary.get(note)!)
      return altNotes
    case MusicalSystem.western:
      // Account for tonic. Then do the same as above.
      altNotes = notes.map((note) => {
        const baseNumericNote: number = note

        // Get array index of base note. e.g., S = 0, r = 1, R = 2, m = 5
        const keys = Array.from(numericToWesternDictionary.keys())
        const baseIndex = keys.indexOf(baseNumericNote)

        // Get array index of tonic. e.g., C = 0, E = 4
        const values = Array.from(numericToWesternDictionary.values())
        const tonicIndex = values.indexOf(tonic)

        // Get note at tonic + base index.
        const entries = Array.from(numericToWesternDictionary.entries())
        const modifiedNote = entries[baseIndex + tonicIndex][1]

        return modifiedNote
      })
      return altNotes
  }
}
