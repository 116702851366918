// Via https://github.com/rfoel/use-prefers-color-scheme

import { useEffect, useState } from "react"

const query = ([mode]: TemplateStringsArray) =>
  `(prefers-color-scheme: ${mode})`
const darkQuery = window.matchMedia?.(query`dark`)
const lightQuery = window.matchMedia?.(query`light`)

export const usePrefersColorScheme = () => {
  const isDark = darkQuery?.matches
  const isLight = lightQuery?.matches

  const [preferredColorSchema, setPreferredColorSchema] = useState(
    isDark ? "dark" : isLight ? "light" : "no-preference"
  )

  // if (typeof window.matchMedia !== "function") {
  //   return preferredColorSchema
  // }

  useEffect(() => {
    if (isDark) setPreferredColorSchema("dark")
    else if (isLight) setPreferredColorSchema("light")
    else setPreferredColorSchema("no-preference")
  }, [isDark, isLight])

  useEffect(() => {
    if (typeof darkQuery!.addEventListener === "function") {
      // In modern browsers MediaQueryList should subclass EventTarget
      // https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList

      const darkListener = ({ matches }: any) =>
        matches && setPreferredColorSchema("dark")
      const lightListener = ({ matches }: any) =>
        matches && setPreferredColorSchema("light")

      darkQuery!.addEventListener("change", darkListener)
      lightQuery!.addEventListener("change", lightListener)

      return () => {
        darkQuery!.removeEventListener("change", darkListener)
        lightQuery!.removeEventListener("change", lightListener)
      }
    }
  }, [])

  return preferredColorSchema
}
