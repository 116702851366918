import { useContext } from "react"
import { MusicalSystem, toAltNotation } from "../utils"
import { Section } from "./design-system/containers"
import { Spacer } from "./design-system/miscellaneous"
import { H2, P } from "./design-system/typography"

import { ConfigContext } from "../contexts/ConfigContext"

export const ChordsDisplay = () => {
  const config = useContext(ConfigContext)
  return (
    <Section>
      <H2>Notes</H2>
      <Spacer />
      <P>
        {config.selectedRaga?.notes!.map((note: number) => `${note} · `)} 1′
      </P>
      <Spacer />
      <P>
        {toAltNotation(
          config.selectedRaga?.notes!,
          config.tonic,
          MusicalSystem.hindustani
        ).map((note: string) => `${note} · `)}{" "}
        S′
      </P>
      <Spacer />
      <P>
        {toAltNotation(
          config.selectedRaga?.notes!,
          config.tonic,
          MusicalSystem.carnatic
        ).map((note: string) => `${note} · `)}{" "}
        S′
      </P>
      <Spacer />
      <P>
        {toAltNotation(
          config.selectedRaga?.notes!,
          config.tonic,
          MusicalSystem.western
        ).map((note: string) => `${note} · `)}
        {config.tonic}′
      </P>
    </Section>
  )
}
