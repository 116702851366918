import styled from "styled-components"
import { Sash } from "./Sash"
import { KeyStrip } from "./KeyStrip"

interface KeyboardProps {
  tonic: string
  numberOfKeys: number
  highlightedNotes: number[]
}

const KeyboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const StyledControls = styled.div`
  height: 2rem;
  background: ${({ theme }) => theme.palette!.gray[9]};
`

const Controls = () => {
  return <StyledControls></StyledControls>
}

export const Keyboard = (props: KeyboardProps) => {
  return (
    <KeyboardContainer>
      <Sash />
      <KeyStrip {...props} highlightedNotes={props.highlightedNotes} />
      <Controls />
    </KeyboardContainer>
  )
}
