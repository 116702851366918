import styled from "styled-components"
import ReactSelect from "react-select"

const StyledSelect = styled(ReactSelect)`
  .react-select {
    &__control {
      background: ${({ theme }) => theme.palette!.gray[9]};
      border-color: ${({ theme }) => theme.palette!.gray[8]};
      border-width: 2px;
      &:hover {
        box-shadow: none;
        outline: none;
        border-color: ${({ theme }) => theme.palette!.gray[7]};
      }
      &--is-focused {
        border-color: ${({ theme }) => theme.palette!.primary[5]};
        outline: none;
        box-shadow: none;
        &:hover {
          border-color: ${({ theme }) => theme.palette!.primary[5]};
        }
      }
    }
    &__single-value {
      color: ${({ theme }) => theme.palette!.gray[0]};
    }
    &__input-container {
      color: ${({ theme }) => theme.palette!.gray[0]};
    }
    &__input {
      color: ${({ theme }) => theme.palette!.gray[0]};
    }
    &__indicator-separator {
      background: ${({ theme }) => theme.palette!.gray[8]};
    }
    &__menu {
      z-index: 4;
      background: ${({ theme }) => theme.palette!.gray[9]};
    }
    &__option {
      &:hover {
        background: ${({ theme }) => theme.palette!.gray[8]};
      }
      &:active {
        background: ${({ theme }) => theme.palette!.gray[7]};
      }
      &--is-focused {
        background: ${({ theme }) => theme.palette!.gray[8]};
      }
      &--is-selected {
        background: ${({ theme }) => theme.palette!.primary[5]};
        &:hover {
          background: ${({ theme }) => theme.palette!.primary[6]};
        }
        &:active {
          background: ${({ theme }) => theme.palette!.primary[7]};
        }
      }
    }
  }
`

export const Select = (props: any) => (
  <StyledSelect classNamePrefix="react-select" {...props} />
)
