import { DefaultTheme } from "styled-components"

const grayHue: number = 40
const graySaturation: number = 5

const primaryHue: number = 40
const primarySaturation: number = 70

const secondaryHue: number = 160
const secondarySaturation: number = 70

const generateColorScale = (
  hue: number,
  middleSaturation: number,
  colorCount: number = 11,
  isFlipped: boolean = false
) => {
  let colorScale: string[] = []
  let i = 0
  do {
    const lightness = i * (colorCount - 1)
    let scaledSaturation = middleSaturation
    if (i < colorCount / 2) {
      scaledSaturation -= middleSaturation * (i / 100)
    } else {
      scaledSaturation += middleSaturation * (i / 100)
    }
    colorScale[i] = `hsl(${hue}, ${scaledSaturation}%, ${lightness}%)`
    i++
  } while (i < colorCount)
  if (isFlipped) return colorScale.reverse()
  return colorScale
}

const generateSpacingScale = (
  baseSpacingUnit: number,
  spacingScaleFactor: number
) => {
  return {
    xxxs: baseSpacingUnit * (spacingScaleFactor * (1 / 4)) + "px",
    xxs: baseSpacingUnit * (spacingScaleFactor * (1 / 2)) + "px",
    xs: baseSpacingUnit * (spacingScaleFactor * 1) + "px",
    s: baseSpacingUnit * (spacingScaleFactor * 2) + "px",
    m: baseSpacingUnit * (spacingScaleFactor * 3) + "px",
    l: baseSpacingUnit * (spacingScaleFactor * 4) + "px",
    xl: baseSpacingUnit * (spacingScaleFactor * 5) + "px",
    xxl: baseSpacingUnit * (spacingScaleFactor * 6) + "px",
    xxxl: baseSpacingUnit * (spacingScaleFactor * 7) + "px",
  }
}

const shared: DefaultTheme = {
  typography: {
    serifFontStack: "'Crimson Pro', 'Newsreader', serif",
    baseFontSize: "1rem",
    typographicScale: 1.414,
  },
  spacing: {
    baseSpacingUnit: 2,
    spacingScaleFactor: 4,
    sizes: generateSpacingScale(2, 4),
  },
  palette: {
    primary: generateColorScale(primaryHue, primarySaturation),
    secondary: generateColorScale(secondaryHue, secondarySaturation),
    gray: generateColorScale(grayHue, graySaturation),
    black: "#000",
    white: "#fff",
  },
}

export const lightTheme: DefaultTheme = {
  ...shared,
}

export const darkTheme: DefaultTheme = {
  ...shared,
  typography: {
    ...shared.typography!,
  },
  palette: {
    ...shared.palette!,
    primary: generateColorScale(primaryHue, primarySaturation, 11, true),
    secondary: generateColorScale(secondaryHue, secondarySaturation, 11, true),
    gray: generateColorScale(grayHue, graySaturation, 11, true),
  },
}
