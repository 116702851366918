import { Section } from "./design-system/containers"
import { H1 } from "./design-system/typography"

export const Header = () => {
  return (
    <Section>
      <H1>Rāga Chords</H1>
    </Section>
  )
}
