import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.palette!.gray[9]};
    font-family: ${({ theme }) => theme.typography!.serifFontStack};
    font-size: ${({ theme }) => theme.typography!.baseFontSize};
    font-weight: 400;
    line-height: ${({ theme }) => theme.typography!.typographicScale};
    color: ${({ theme }) => theme.palette!.gray[0]};
  }
`
