import styled from "styled-components"
import {
  MusicalSystem,
  numericToWesternDictionary,
  toAltNotation,
} from "../../utils"
import { Key } from "./Key"

const westernNotes: string[] = Array.from(numericToWesternDictionary.values())
const colorPattern: number[] = [0, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1, 0]

interface KeyStripProps {
  tonic: string
  numberOfKeys: number
  highlightedNotes: number[]
}

const StyledKeyStrip = styled.div`
  display: flex;
`

export const KeyStrip = (props: KeyStripProps) => {
  const highlightedWesternNotes = toAltNotation(
    props.highlightedNotes,
    props.tonic,
    MusicalSystem.western
  )
  const keys = [...Array(props.numberOfKeys)].map((key, index) => {
    const tonicIndex = westernNotes.indexOf(props.tonic)
    const keyNumber = index % colorPattern.length
    const isBlack = colorPattern[keyNumber] === 1
    const isNextToBlackKey = colorPattern[keyNumber - 1] === 1
    const isTonic = tonicIndex === keyNumber
    const isHighlighted = highlightedWesternNotes.includes(
      westernNotes[keyNumber]
    )
    const note = { western: westernNotes[keyNumber] }
    const isDominant = isHighlighted && index === tonicIndex + 7
    let isKeyInPrimaryOctave = index >= tonicIndex && index <= tonicIndex + 12
    const octave = index < 12 ? 3 : 4
    return (
      <Key
        octave={octave}
        note={note}
        key={index}
        color={isBlack ? "black" : "white"}
        isNextToBlackKey={isNextToBlackKey}
        isHighlighted={isHighlighted}
        isTonic={isTonic}
        isKeyInPrimaryOctave={isKeyInPrimaryOctave}
        isDominant={isDominant}
      />
    )
  })
  return <StyledKeyStrip>{keys}</StyledKeyStrip>
}
