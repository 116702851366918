import { useEffect, useState } from "react"
import { ThemeProvider } from "styled-components"
import { Reset as StyledReset } from "styled-reset"
import * as Tone from "tone"
import hindustaniRagas from "./assets/data/hindustaniRagas.json"
import { ChordsDisplay } from "./components/ChordsDisplay"
import { Container, Sticky } from "./components/design-system/containers"
import { GlobalStyle } from "./components/design-system/GlobalStyle"
import { Divider } from "./components/design-system/miscellaneous"
import { darkTheme, lightTheme } from "./components/design-system/themes"
import { Header } from "./components/Header"
import { Keyboard } from "./components/keyboard/Keyboard"
import { RagaSelection } from "./components/RagaSelection"
import { ConfigContext } from "./contexts/ConfigContext"
import { ToneContext } from "./contexts/ToneContext"
import { usePrefersColorScheme } from "./hooks/usePrefersColorScheme"
import { Config, MusicalSystem, Raga } from "./utils"

function App() {
  const prefersColorScheme = usePrefersColorScheme()
  const isDarkMode = prefersColorScheme === "dark"
  const [sampler, setSampler] = useState<Tone.Sampler>({} as Tone.Sampler)

  useEffect(() => {
    const extension = "wav"
    // const extension = "mp3"
    const tremolo = new Tone.Tremolo(0.5, 1).toDestination().start()
    const vibrato = new Tone.Vibrato(0.5, 0.5).toDestination()
    const loadSampler = new Tone.Sampler({
      attack: 0,
      release: 0.48,
      urls: {
        C4: `C4.${extension}`,
        "D#4": `Ds4.${extension}`,
        "F#4": `Fs4.${extension}`,
        A4: `A4.${extension}`,
      },
      // baseUrl: "https://tonejs.github.io/audio/salamander/", // piano
      baseUrl: "/assets/samples/harmonium/", // harmonium
    })
      .connect(tremolo)
      .connect(vibrato)
      .toDestination()

    Tone.loaded().then(() => {
      setSampler(loadSampler)
    })
  }, [])

  const [config, setConfig] = useState<Config>({
    selectedRaga: hindustaniRagas.find(
      (raga: Raga) => raga.name === "Bhairavī"
    ),
    ragaSystem: MusicalSystem.hindustani,
    notationSystem: MusicalSystem.hindustani,
    tonic: "C",
  })

  const handleRagaChange = (raga: Raga) => {
    setConfig({ ...config, selectedRaga: raga })
  }

  const handleTonicChange = (tonic: string) => {
    setConfig({ ...config, tonic: tonic })
  }

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <ToneContext.Provider value={sampler}>
        <ConfigContext.Provider value={config}>
          <Container>
            <StyledReset />
            <GlobalStyle />
            <Sticky>
              <Header />
              <Divider />
              <RagaSelection
                onRagaChange={(raga: Raga) => handleRagaChange(raga)}
                onTonicChange={(tonic: string) => handleTonicChange(tonic)}
              />
              <Keyboard
                tonic={config.tonic}
                highlightedNotes={config.selectedRaga!.notes}
                numberOfKeys={24}
              />
              <Divider />
            </Sticky>
            <ChordsDisplay />
          </Container>
        </ConfigContext.Provider>
      </ToneContext.Provider>
    </ThemeProvider>
  )
}

export default App
