import { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import hindustaniRagas from "../assets/data/hindustaniRagas.json"
import { ConfigContext } from "../contexts/ConfigContext"
import { numericToWesternDictionary, Raga } from "../utils"
import { Section } from "./design-system/containers"
import { Select } from "./design-system/inputs"
import { P } from "./design-system/typography"

interface RagaSelectionProps {
  onRagaChange: any
  onTonicChange: any
}

const Splitter = styled.section`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 1ch;
  & > *:first-child {
    flex: 2;
  }
  & > *:last-child {
    flex: 1;
  }
`

export const RagaSelection = (props: RagaSelectionProps) => {
  const config = useContext(ConfigContext)
  const [selectedRaga, setSelectedRaga] = useState<Raga>(config.selectedRaga!)
  const [currentTonic, setCurrentTonic] = useState<string>(config.tonic!)

  const handleRagaChange = (newRaga: Raga) => {
    setSelectedRaga(hindustaniRagas.find((raga) => raga.name === newRaga.name)!)
  }

  const handleTonicChange = (newTonic: any) => {
    setCurrentTonic(newTonic.value)
  }

  const westernNotesOptions = Array.from(numericToWesternDictionary.values())
    .slice(12)
    .map((note) => ({ label: note, value: note }))

  useEffect(() => {
    props.onRagaChange(selectedRaga)
  }, [selectedRaga])

  useEffect(() => {
    props.onTonicChange(currentTonic)
  }, [currentTonic])

  return (
    <>
      <Section>
        <Splitter>
          <Select
            defaultValue={selectedRaga}
            options={hindustaniRagas as Raga[]}
            getOptionLabel={(raga: Raga) => raga.name}
            getOptionValue={(raga: Raga) => raga.name}
            onChange={(raga: Raga) => handleRagaChange(raga)}
            inputProps={{ readonly: "true" }}
          />
          <P>at</P>
          <Select
            defaultValue={{ label: currentTonic, value: currentTonic }}
            options={westernNotesOptions}
            onChange={(tonic: string) => handleTonicChange(tonic)}
            inputProps={{ readonly: "true" }}
          />
        </Splitter>
      </Section>
    </>
  )
}
